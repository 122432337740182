import React, { Component } from 'react'
import {Container,Row,Col,Form,InputGroup,FormControl,Button,Alert,Toast,Modal} from 'react-bootstrap'
import styled from 'styled-components';
import DataGrid from '../Components/Misc/DataGrid';
import profileicon from '../assets/images/profile_Icon.png';

const FormHeader=styled.p`
    margin-top: 31px;
    margin-bottom: 1rem;
    // background-color: white;
    font-weight: bold;
    text-align: center;
    font-size:50px;
    `;
    
class UserActivation extends Component {

    constructor(props) {
        super(props);
        this.state = {
          rolename: '',
          roledescription: '',
          users:[],
          useramo:'',
          userrsm:'',
          userhead:'',
          isstore:0,
          isaom:0,
          isrsm:0,
          ishead:0,
          ready:false,
          showtoast1:false,
          showtoast2:false,
          showtoast3:false,
          error:'',
          roleeditid:0,
          userid:0,
          showmodal:false,
          showmodal2:false,
          showmodal3:false,
          editfirstname:'',
          editusername:'',
          editlastname:'',
          editmiddlename:'',
          columnstoview:["UserName","FirstName","MiddleName","LastName","UserType","AOM","RSM","Head","Status"],
          
           
          }
    }

    componentDidMount() { 
  //gbconsole.log(localStorage.getItem("Users"));
        var users=JSON.parse(localStorage.getItem("Users") || "[]");
       this.setState({users:users},()=>{this.setState({ready:true})});
       //gbconsole.log('users=',users);
      }
      userchangepassword=(userid,username)=>{
        console.log('userid=',userid)
        console.log('username=',username)
       this.props.refreshCallback("changepassword2",userid,username)
      }
      
      activationcallback=(editid)=>{
        //gbconsole.log('editid=',editid)

        let postData = {
     
          
          "UserID":editid
    
           }
         //gbconsole.log('postdata=',postData)
         let url = process.env.REACT_APP_DEBUG_URL+'/api/updateuserstatus';
         let token =localStorage.getItem("UserToken");
         const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json','Authorization': 'Bearer '+token },
          body: JSON.stringify(postData)
         // body: postData
      };
    
      fetch(url, requestOptions)
            .then(res => res.json())
            .then(result => {
              //gbconsole.log(result)          
              var status=result['status'];
              
        if(status=='Failure'){
        
        //gbconsole.log("error=",result['error'])
     //   this.setState({error:result['error']});
       
     //   this.setState({showtoast2:true});
              
        }else
        {
           
            localStorage.setItem("Users", JSON.stringify(result.users));
            var users=JSON.parse(localStorage.getItem("Users") || "[]");
            this.setState({ready:false,users:users},()=>{this.setState({ready:true})});
            //gbconsole.log('users=',users);
           
            
        }   
    
            }); 



      }

      seteditid=(editid)=>{
        console.log('editid=',editid,'users=',this.state.users)
        this.setState({editid:editid})
        var users=[... this.state.users];
        var firstname='';
        var middlename='';
        var lastname='';
        var username='';
        var amoid=0;
        var rsmid=0;
        var headid=0;
        var isstore=0;
        var isaom=0;
        var ishead=0;
        var isrsm=0;

        for(var v=0;v<users.length;v++){
          if(users[v]['ID']==editid){
             firstname=users[v]['FirstName'];
             middlename=users[v]['MiddleName'];
             lastname=users[v]['LastName'];
             username=users[v]['UserName'];

             amoid=users[v]['AmoID']==null?0:users[v]['AmoID'];
             rsmid=users[v]['RsmID']==null?0:users[v]['RsmID'];
             headid=users[v]['HeadID']==null?0:users[v]['HeadID'];

             isstore=users[v]['IsStore']==null?0:users[v]['IsStore'];
             isaom=users[v]['IsAom']==null?0:users[v]['IsAom'];
             ishead=users[v]['IsHead']==null?0:users[v]['IsHead'];
             isrsm=users[v]['IsRsm']==null?0:users[v]['IsRsm'];
             break;
          } 
        }
        this.setState({userid:editid,editfirstname:firstname,editmiddlename:middlename,editlastname:lastname,editusername:username,useramo:amoid,userrsm:rsmid,userhead:headid,isstore:isstore,isaom:isaom,isrsm:isrsm,ishead:ishead},()=>{console.log(this.state.editusername);
        console.log(amoid,rsmid,headid)
          if(isstore!=0)
          this.setState({showmodal:true});
         if(isaom!=0)
          this.setState({showmodal2:true});
         if(isrsm!=0)
          this.setState({showmodal3:true});
          });
      }
      hideModal=()=>{
        console.log('drows1=',localStorage.getItem('DropDownRows'));
        this.setState({showmodal:false})
      }
      hideModal2=()=>{
        console.log('drows1=',localStorage.getItem('DropDownRows'));
        this.setState({showmodal2:false})
      }
      hideModal3=()=>{
        console.log('drows1=',localStorage.getItem('DropDownRows'));
        this.setState({showmodal3:false})
      }
      handleChange = (event) => {    
        console.log(event.target.value)
        this.setState({[event.target.name]: event.target.value});    
      }
      submitUser=(event)=>{
        event.preventDefault();
        console.log(this.state.useramo,'--',this.state.editid)
        let url = process.env.REACT_APP_DEBUG_URL+'/api/updateuseramo';
         let token =localStorage.getItem("UserToken");
        let postData = {
     
          
          "UserID":this.state.editid,
          "AmoUserID":this.state.useramo,
          "RsmUserID":this.state.userrsm,
          "HeadUserID":this.state.userhead,
          "IsStore":this.state.isstore,
          "IsAom":this.state.isaom,
          "IsRsm":this.state.isrsm,
          "IsHead":this.state.ishead
    
           }

           const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json','Authorization': 'Bearer '+token },
            body: JSON.stringify(postData)
           
        };
      
        fetch(url, requestOptions)
              .then(res => res.json())
              .then(result => {
                console.log(result)          
                var status=result['status'];
                
          if(status=='Failure'){
          
          //gbconsole.log("error=",result['error'])
       //   this.setState({error:result['error']});
         
       //   this.setState({showtoast2:true});
                
          }else
          {
             
             localStorage.setItem("Users", JSON.stringify(result.users));
           var users=JSON.parse(localStorage.getItem("Users") || "[]");
             this.setState({ready:false,users:users,showtoast1:true,showmodal:false,editid:0,useramo:0},()=>{this.setState({ready:true})});
              //gbconsole.log('users=',users);
             
              
          }   
      
              }); 
      }
      toggleShowA=(event)=>{
        this.setState({showtoast1:false});
      }
      toggleShowB=(event)=>{
        this.setState({showtoast2:false});
      }
      toggleShowC=(event)=>{
        this.setState({showtoast3:false});
      }
      processdata(){
        let tdata=[];
        let tabledata=[...this.state.users];
        for(var i=0;i<tabledata.length;i++){
          console.log(tabledata[i])

          if(tabledata[i].RoleID==4||tabledata[i].RoleID==23||tabledata[i].RoleID==24||tabledata[i].RoleID==21)continue;
          var roleid=tabledata[i].RoleID;
          console.log(roleid);
          var usertype='';
          switch(roleid){
          case 22:
          usertype='STORE';
          break;
          case 25:
          usertype='AOM';
          break;
          case 26:
          usertype='RSM';
          break;
          case 27:
          usertype='HEAD';
          break;


          }
          tdata[tdata.length]=tabledata[i];
          
          tdata[tdata.length-1]['UserType']=usertype;
          tdata[tdata.length-1]['AOM']=tdata[tdata.length-1]['Amo'];
        }
        return tdata;
      }
    render() {
        if(this.state.ready==false)
        return(<div></div>)
        else
 

    return (
      <div>
      <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
             
            
             
             
              
             <ul className="navbar-nav ml-auto">
              
               <li className="nav-item dropdown no-arrow d-sm-none">
                 
                
                 <div className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in" aria-labelledby="searchDropdown">
                  
                 </div>
               </li>
               
               <li className="nav-item dropdown no-arrow mx-1">
                
                 <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="alertsDropdown">
                   
                 </div>
               </li>
              
               <li className="nav-item dropdown no-arrow mx-1">
                
                
                 <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="messagesDropdown">
                   
                 </div>
               </li>
               <div className="topbar-divider d-none d-sm-block" />
              
               <li className="nav-item dropdown no-arrow">
                 <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                   <span className="mr-2 d-none d-lg-inline text-gray-600 small">{localStorage.getItem('FirstName')}</span>
                   <img className="img-profile rounded-circle" src={profileicon} />
                 </a>
               
                  
               </li>
             </ul>
           </nav>
        <Container>

        <Toast show={this.state.showtoast1} onClose={this.toggleShowA} className="toast-border">
<Toast.Header>            
<strong className="mr-auto toast-header-success">Success</strong>                    
</Toast.Header>
<Toast.Body> Aom updated</Toast.Body>
</Toast>

<Toast show={this.state.showtoast2} onClose={this.toggleShowB} className="toast-border">
<Toast.Header>            
<strong className="mr-auto toast-header-success">Success</strong>                    
</Toast.Header>
<Toast.Body> RSM updated</Toast.Body>
</Toast>

<Toast show={this.state.showtoast3} onClose={this.toggleShowC} className="toast-border">
<Toast.Header>            
<strong className="mr-auto toast-header-success">Success</strong>                    
</Toast.Header>
<Toast.Body> Head updated</Toast.Body>
</Toast>




        <Modal  size='xl'  show={this.state.showmodal}  aria-labelledby="contained-modal-title-vcenter">
    
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">
         Update User
      </Modal.Title>
     
    </Modal.Header>
    <p className='modalsubtitle'></p>
    <Modal.Body className="show-grid">
    
      <Container>
      <Row>
      
      <Col>
      
      <Form >







<Row className='bottomconfirm'>
<Col>
<p>User Name:{this.state.editusername}</p>
<p>Name:{this.state.editfirstname}&nbsp;{this.state.editmiddlename}&nbsp;{this.state.editlastname}</p>

<Form.Group controlId="formBasicEmail">
    <Form.Label>AOM</Form.Label>
   

                   <Form.Control as="select" id="selectedamo"   className="margin-5 w-60" value={this.state.useramo} onChange={this.handleChange} name='useramo'   >
      <option key ={0} value={0}>Select AOM</option>
      {
          
                  (()=>{
                    var users=[... this.state.users];
                    if(users.length>0){
                    
                      let dropvalues=[];
                        
                      for(var v=0;v<users.length;v++){
                          if(users[v]['RoleID']==25){
                              
                              
                            dropvalues.push(<option   key={users[v]['ID']}   value={users[v]['ID']}>{users[v]['FirstName']+' '+(users[v]['MiddleName']==null?'':users[v]['MiddleName'])+' '+users[v]['LastName']} </option>);
                        

                          }
                       
                                             
                        
                        
    
                    }
                   
                    return dropvalues;
    
                    }
                    else
                    return null;
                      
                        
                   })()
                }
 </Form.Control>


    
    
  </Form.Group>
</Col>
</Row>
<Row>
  <Col>
<Button variant="primary" type="button" onClick={this.submitUser}>
    Submit
  </Button>
  </Col>
</Row>
<Row>
<Col></Col>
</Row>




</Form>




      </Col>
 
 </Row>
      </Container>
    </Modal.Body>
    <Modal.Footer>
    <Button onClick={this.hideModal}>Close</Button>
    </Modal.Footer>
    
  </Modal>



  <Modal  size='xl'  show={this.state.showmodal2}  aria-labelledby="contained-modal-title-vcenter">
    
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">
         Update User
      </Modal.Title>
     
    </Modal.Header>
    <p className='modalsubtitle'></p>
    <Modal.Body className="show-grid">
    
      <Container>
      <Row>
      
      <Col>
      
      <Form >







<Row className='bottomconfirm'>
<Col>
<p>User Name:{this.state.editusername}</p>
<p>Name:{this.state.editfirstname}&nbsp;{this.state.editmiddlename}&nbsp;{this.state.editlastname}</p>

<Form.Group controlId="formBasicEmail">
    <Form.Label>RSM</Form.Label>
   

                   <Form.Control as="select" id="selectedamo"   className="margin-5 w-60" value={this.state.userrsm} onChange={this.handleChange} name='userrsm'   >
      <option key ={0} value={0}>Select RSM</option>
      {
          
                  (()=>{
                    var users=[... this.state.users];
                    if(users.length>0){
                    
                      let dropvalues=[];
                        
                      for(var v=0;v<users.length;v++){
                          if(users[v]['RoleID']==26){
                              
                              
                            dropvalues.push(<option   key={users[v]['ID']}   value={users[v]['ID']}>{users[v]['FirstName']+' '+(users[v]['MiddleName']==null?'':users[v]['MiddleName'])+' '+users[v]['LastName']} </option>);
                        

                          }
                       
                                             
                        
                        
    
                    }
                   
                    return dropvalues;
    
                    }
                    else
                    return null;
                      
                        
                   })()
                }
 </Form.Control>


    
    
  </Form.Group>
</Col>
</Row>
<Row>
  <Col>
<Button variant="primary" type="button" onClick={this.submitUser}>
    Submit
  </Button>
  </Col>
</Row>
<Row>
<Col></Col>
</Row>




</Form>




      </Col>
 
 </Row>
      </Container>
    </Modal.Body>
    <Modal.Footer>
    <Button onClick={this.hideModal2}>Close</Button>
    </Modal.Footer>
    
  </Modal>




  <Modal  size='xl'  show={this.state.showmodal3}  aria-labelledby="contained-modal-title-vcenter">
    
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">
         Update User
      </Modal.Title>
     
    </Modal.Header>
    <p className='modalsubtitle'></p>
    <Modal.Body className="show-grid">
    
      <Container>
      <Row>
      
      <Col>
      
      <Form >







<Row className='bottomconfirm'>
<Col>
<p>User Name:{this.state.editusername}</p>
<p>Name:{this.state.editfirstname}&nbsp;{this.state.editmiddlename}&nbsp;{this.state.editlastname}</p>

<Form.Group controlId="formBasicEmail">
    <Form.Label>Head</Form.Label>
   

                   <Form.Control as="select" id="selectedamo"   className="margin-5 w-60" value={this.state.userhead} onChange={this.handleChange} name='userhead'   >
      <option key ={0} value={0}>Select Head</option>
      {
          
                  (()=>{
                    var users=[... this.state.users];
                    if(users.length>0){
                    
                      let dropvalues=[];
                        
                      for(var v=0;v<users.length;v++){
                          if(users[v]['RoleID']==27){
                              
                              
                            dropvalues.push(<option   key={users[v]['ID']}   value={users[v]['ID']}>{users[v]['FirstName']+' '+(users[v]['MiddleName']==null?'':users[v]['MiddleName'])+' '+users[v]['LastName']} </option>);
                        

                          }
                       
                                             
                        
                        
    
                    }
                   
                    return dropvalues;
    
                    }
                    else
                    return null;
                      
                        
                   })()
                }
 </Form.Control>


    
    
  </Form.Group>
</Col>
</Row>
<Row>
  <Col>
<Button variant="primary" type="button" onClick={this.submitUser}>
    Submit
  </Button>
  </Col>
</Row>
<Row>
<Col></Col>
</Row>




</Form>




      </Col>
 
 </Row>
      </Container>
    </Modal.Body>
    <Modal.Footer>
    <Button onClick={this.hideModal3}>Close</Button>
    </Modal.Footer>
    
  </Modal>


            <Row>
                <Col>
        <FormHeader>Existing Users</FormHeader>
              
        <DataGrid  rowsarray={this.processdata()}  activationcallback={this.activationcallback } userchangepassword={this.userchangepassword} seteditid={this.seteditid }  columns={this.state.columnstoview} />

        </Col>
  </Row>
  </Container>
  </div>
    );
    }
}

export default UserActivation;
