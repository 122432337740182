import React, { Component } from 'react'
import {Container,Row,Col,Form,InputGroup,FormControl,Button,Alert,Toast,Modal} from 'react-bootstrap'
import styled from 'styled-components';
import DataGrid from './Misc/DataGrid';
import RoleEditModal from './RoleEditModal';
import profileicon from '../assets/images/profile_Icon.png';
const FormHeader=styled.p`
    margin-top: 31px;
    margin-bottom: 1rem;
    background-color: white;
    font-weight: bold;
    text-align: center;
    font-size:50px;`;

    const AddRole=styled.div`
    display:flex;
    justify-content:flex-end;
    margin-top:15px;
    margin-right:15px;
    `;

export class Userc extends Component {


  constructor(props) {
    super(props);
    this.state = {
        FirstName: '',
        MiddleName: '',
        LastName:'',
        UserName:'',
        Email:'',
        Password:'',
        ConfirmPassword:'',
        userrole:'',
      
      ready:false,
      showtoast1:false,
      showtoast2:false,
      showtoast3:false,
      error:'',
      roleeditid:0,
      showmodal:false,
      errors: {},
      users:[],
      useramo:''
      
       
      }
}
toggleShowA=(event)=>{
  this.setState({showtoast1:false});
}
toggleShowB=(event)=>{
  this.setState({showtoast2:false});
  this.setState({error:''});
}
toggleShowC=(event)=>{
  this.setState({showtoast3:false});
}

componentDidMount() { 
  
 this.setState({ready:true});
 this.initialize(0);
}

initialize(editid){
  let postData = {
     
          
    "UserID":editid

     }
  let url = process.env.REACT_APP_DEBUG_URL+'/api/getdetailsforusercreation';
         let token =localStorage.getItem("UserToken");
         const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json','Authorization': 'Bearer '+token },
          body: JSON.stringify(postData)
         // body: postData
      };
    
      fetch(url, requestOptions)
            .then(res => res.json())
            .then(result => {
              console.log(result)          
              var status=result['status'];
              this.setState({ready:false,users:result['users']},()=>{this.setState({ready:true})});
              
        // if(status=='Failure'){
        
      
              
        // }else
        // {
           
        //     localStorage.setItem("Users", JSON.stringify(result.users));
        //     var users=JSON.parse(localStorage.getItem("Users") || "[]");
        //     this.setState({ready:false,users:users},()=>{this.setState({ready:true})});
           
           
            
        // }   
    
            }); 
}

  submitUser=(event)=>{
    event.preventDefault();
    if(this.state.Password!=this.state.ConfirmPassword){
      let errors={};
      errors["PasswordHash"] ='Password doesnot match confirm password!' ;
      this.setState({errors: errors});
      return;
    }
    
    let postData = {
     
      "FirstName": this.state.FirstName ,   
      "MiddleName":this.state.MiddleName,
      "LastName":this.state.LastName,
      "Email":this.state.Email,
      "UserName":this.state.UserName,
      "LastName":this.state.LastName,
      "PasswordHash":this.state.Password,
      "UserRole":this.state.userrole,
      "Amo":this.state.useramo

       }
       //gbconsole.log('postdata=',postData)
         let url = process.env.REACT_APP_DEBUG_URL+'/api/createuser';
         let token =localStorage.getItem("UserToken");
         const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json','Authorization': 'Bearer '+token },
          body: JSON.stringify(postData)
         // body: postData
      };

  fetch(url, requestOptions)
        .then(res => res.json())
        .then(result => {
          //gbconsole.log(result)          
         var status=result['status'];
          
     if(status=='Success'){
    
  
      let errors = {};
     this.setState({FirstName:'',MiddleName:'',LastName:'',Email:'',UserName:'',Password:'',ConfirmPassword:'',showmodal:false,errors:errors});
     this.setState({showtoast1:true});
     localStorage.setItem("Users", JSON.stringify(result.users));
     localStorage.setItem("UserRolesAssigned", JSON.stringify(result.rolesassigned));
    
          
     }else
     {
    //   //  //gbconsole.log("error=",result['error'])
    let errors = {};
    errors["FirstName"] =result['validationerror']['FirstName'] ;
    errors["LastName"] =result['validationerror']['LastName'] ;
    errors["UserName"] =result['validationerror']['UserName'] ;
    errors["Email"] =result['validationerror']['Email'] ;
    errors["PasswordHash"] =result['validationerror']['PasswordHash'] ;
    errors["userrole"] =result['validationerror']['UserRole'] ;
    //gbconsole.log('errors=',errors);
    


         this.setState({error:'Error',errors: errors});
       
         this.setState({showtoast2:true});
      
     }   

        }).catch(err=>{
          //gbconsole.log(err)
      }); 


  }
  markup (val) {
		return { __html: val }
	}
  hideModal=()=>{
    this.setState({showmodal:false,roleeditid:0});
}
  handleChange = (event) => {    
    this.setState({[event.target.name]: event.target.value});    
  }
  seteditid=(editid)=>{
   
    for(var i=0;i<this.state.userroles.length;i++){
      if(this.state.userroles[i]['ID']==editid){
        this.setState({rolename:this.state.userroles[i]['Name'],roledescription:this.state.userroles[i]['Description']});
        break;
      }
    }
  this.setState({roleeditid:editid},()=>{
    //gbconsole.log('roleeditid=',this.state.roleeditid)
    this.setState({showmodal:true},()=>{
     
    })
  });
  
  }
  
    render() {
      
            if(this.state.ready==false)
            return(<div></div>)
            else
     

        return (
          
              
          <div>
          <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
                 
                
                 
                 
                  
                 <ul className="navbar-nav ml-auto">
                  
                   <li className="nav-item dropdown no-arrow d-sm-none">
                     
                    
                     <div className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in" aria-labelledby="searchDropdown">
                      
                     </div>
                   </li>
                   
                   <li className="nav-item dropdown no-arrow mx-1">
                    
                     <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="alertsDropdown">
                       
                     </div>
                   </li>
                  
                   <li className="nav-item dropdown no-arrow mx-1">
                    
                    
                     <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="messagesDropdown">
                       
                     </div>
                   </li>
                   <div className="topbar-divider d-none d-sm-block" />
                  
                   <li className="nav-item dropdown no-arrow">
                     <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                       <span className="mr-2 d-none d-lg-inline text-gray-600 small">{localStorage.getItem('FirstName')}</span>
                       <img className="img-profile rounded-circle" src={profileicon} />
                     </a>
                   
                      
                   </li>
                 </ul>
               </nav>

             <div>
              <FormHeader>Create user</FormHeader>
              <Form onSubmit={this.submitUser}>
                 <Container>
              <Row>
              <Col xs="12" lg="4">
  <Form.Group controlId="formBasicEmail">
    <Form.Label>First Name<b style={{color: "red"}}>(*)</b></Form.Label>
    <Form.Control type="text"  name="FirstName" value={this.state.FirstName} onChange={this.handleChange} />
    <span style={{color: "red"}}>{this.state.errors["FirstName"]}</span>
    
  </Form.Group>
  </Col>
  <Col xs="12" lg="4">
  <Form.Group controlId="formBasicEmail">
    <Form.Label>Middle Name</Form.Label>
    <Form.Control type="text"  name="MiddleName" value={this.state.MiddleName} onChange={this.handleChange} />
    <span style={{color: "red"}}>{this.state.errors["MiddleName"]}</span>
    
  </Form.Group>
  </Col>
  <Col xs="12" lg="4">
  <Form.Group controlId="formBasicEmail">
    <Form.Label>Last Name
      {/* <b style={{color: "red"}}>(*)</b> */}
    
    </Form.Label>
    <Form.Control type="text"  name="LastName" value={this.state.LastName} onChange={this.handleChange} />
    <span style={{color: "red"}}>{this.state.errors["LastName"]}</span>
    
  </Form.Group>
</Col>
</Row>
<Row>
  
<Col xs="12" lg="6">
  <Form.Group controlId="formBasicEmail">
    <Form.Label>User Name<b style={{color: "red"}}>(*)</b></Form.Label>
    <Form.Control type="text"  name="UserName" value={this.state.UserName} onChange={this.handleChange} />
    <span style={{color: "red"}}>{this.state.errors["UserName"]}</span>
    
  </Form.Group>
  </Col>
  <Col xs="12" lg="6">
  <Form.Group controlId="formBasicEmail">
    <Form.Label>Email<b style={{color: "red"}}>(*)</b></Form.Label>
    <Form.Control type="email"  name="Email" value={this.state.Email} onChange={this.handleChange} />
    <span style={{color: "red"}}>{this.state.errors["Email"]}</span>
    
  </Form.Group>
  </Col>
  </Row>
  <Row>
   
  <Col xs="12" lg="6">
  <Form.Group controlId="formBasicEmail">
    <Form.Label>Password<b style={{color: "red"}}>(*)</b></Form.Label>
    <Form.Control type="password"  name="Password" value={this.state.Password} onChange={this.handleChange} />
    <span style={{color: "red"}}>{this.state.errors["PasswordHash"]}</span>
    
  </Form.Group>
  </Col>
  <Col xs="12" lg="6">
  <Form.Group controlId="formBasicEmail">
    <Form.Label>Confirm Password<b style={{color: "red"}}>(*)</b></Form.Label>
    <Form.Control type="password"  name="ConfirmPassword" value={this.state.ConfirmPassword} onChange={this.handleChange} />
    
  </Form.Group>
  </Col>
  </Row>



  <Row>
   
  <Col xs="12" lg="6">
  <Form.Group controlId="formBasicEmail">
    <Form.Label>User Type<b style={{color: "red"}}>(*)</b></Form.Label>
    <Form.Control as="select"      id="selectedrole" className="margin-5" value={this.state.userrole} onChange={this.handleChange} name='userrole'>
                      <option key ={0} value={0}>Select </option>
                      <option key ={1} value={22}>Store </option>
                      <option key ={2} value={25}>AOM </option>
                      <option key ={3} value={26}>RSM </option>
                      <option key ={4} value={27}>Head </option>
                    
                    
                   </Form.Control>   
    <span style={{color: "red"}}>{this.state.errors["userrole"]}</span>
    
  </Form.Group>
  </Col>

  <Col xs="12" lg="6">
  {/* <Form.Group controlId="formBasicEmail">
    <Form.Label>AOM</Form.Label>
  

                   <Form.Control as="select" id="selectedamo"   className="margin-5 w-60" value={this.state.useramo} onChange={this.handleChange} name='useramo'   >
      <option key ={0} value={0}>Select AOM</option>
      {
          
                  (()=>{
                    var users=[... this.state.users];
                    if(users.length>0){
                    
                      let dropvalues=[];
                        
                      for(var v=0;v<users.length;v++){
                          if(users[v]['RoleID']==25){
                              
                              
                            dropvalues.push(<option   key={users[v]['UserID']}   value={users[v]['UserID']}>{users[v]['FirstName']+' '+(users[v]['MiddleName']==null?'':users[v]['MiddleName'])+' '+users[v]['LastName']} </option>);
                        

                          }
                       
                                             
                        
                        
    
                    }
                   
                    return dropvalues;
    
                    }
                    else
                    return null;
                      
                        
                   })()
                }
 </Form.Control>


    <span style={{color: "red"}}>{this.state.errors["useramo"]}</span>
    
  </Form.Group> */}
  </Col>
  
  </Row>


  
<Row>
  <Col>
<Button variant="primary" type="submit">
    Submit
  </Button>
  </Col>
</Row>
  
  
 
  
   </Container>
  
</Form>

<Toast show={this.state.showtoast1} onClose={this.toggleShowA} className="toast-border">
<Toast.Header>            
<strong className="mr-auto toast-header-success">Success</strong>                    
</Toast.Header>
<Toast.Body>User created/updated</Toast.Body>
</Toast>

<Toast show={this.state.showtoast2} onClose={this.toggleShowB} className="toast-border">
<Toast.Header>            
<strong className="mr-auto toast-header-failure">Failure</strong>                    
</Toast.Header>
<Toast.Body dangerouslySetInnerHTML={this.markup(this.state.error)}></Toast.Body>
</Toast>
</div>
             
       </div>    









        )
    }
}

export default Userc
